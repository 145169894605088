import { Injectable } from "@angular/core";

@Injectable()
export class Globals {
  apiUrl: string = "https://api.pioneroslm.com";
  permisosdisponibles = [
    "CATEGORIAS",
    "DASHBOARD",
    "DESCUENTOS",
    "PRODUCTOS",
    "USUARIOS",
    "VENTAS",
    "NOTICIAS",
    "PATROCINADORES",
    "BANNERS",
    "ROSTER",
    "ALBUMES",
    "MARCAS",
    "VARIANTES",
    "BOLETOS",
    "ULTIMOS MOVIMIENTOS",
    "CONFIGURACION"
  ];
}
